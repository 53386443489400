import { render } from "react-dom";
import React, { useState, useEffect, useRef } from "react";
import { Canvas, useThree } from "react-three-fiber";
import { Html } from "drei";
import { Block } from "./blocks";
import { Shapes, Shapes2, Categories, Box } from "./Home";
import state from "./store";
import "./styles.css";
import Technology from "./tech";
import Examples from "./../../views/IndexSections/Examples";
import { Button, Container, Row, Col } from "reactstrap";



function HtmlContent({ className, style, children, portal }) {
  const { size } = useThree();
  return (
    <Html
      portal={portal}
      style={{
        position: "absolute",
        top: -size.height / 2,
        left: -size.width / 2,
        width: size.width,
        height: size.height,
      }}
    >
      <div className={className} style={style}>
        {children}
      </div>
    </Html>
  );
}

function App() {
  const [events, setEvents] = useState();
  const domContent = useRef();
  const scrollArea = useRef();
  const onScroll = (e) => (state.top.current = e.target.scrollTop);
  useEffect(() => void onScroll({ target: scrollArea.current }), []);

  // const {
  //   viewport: { width, height },
  // } = useThree();
  
  // #205374 
  // #27a09e 
  // #30ce88 
  // #7de393 
  // #d3f5ce

  return (
    <>
      <Canvas
        colorManagement
        gl={{ alpha: false, antialias: true }}
        camera={{ position: [0, 0, 4.5], fov: 50, near: 0.1, far: 100 }}
        onCreated={({ gl, events }) => {
          // gl.setClearColor("rgb(54,54,54)");
          gl.setClearColor("#205374");

          gl.toneMappingExposure = 2.5;
          gl.toneMappingWhitePoint = 1;
          // Export canvas events, we will put them onto the scroll area
          setEvents(events);
        }}
        style={{ backgroundColor: "linear-gradient(red, yellow);" }}
      >
        <Block factor={1.5} offset={0}>
          <Shapes />
          <HtmlContent portal={domContent}>
            {/* <div className="menu left" style={{ top: "2.55rem" }}>
              <h2 style={{ fontSize: "2em", top: "4rem", color: "white" }}>
                Ignace Loomans Consulting
              </h2>
            </div>
            <div className="menu right">
              <span style={{ color: "white" }}>Login</span>
              <span style={{ color: "white" }}>Sign up</span>
            </div> */}
            <div className="jumbo">
              <h1 style={{ color: "white" }}>
                Trusted
                <br />
                Blockchain
                <br />
                Developer.
              </h1>
              <Categories />
            </div>
          </HtmlContent>
        </Block>

        <Block factor={1.5} offset={1.75}>
          <Box />
        </Block>
        <Block factor={1.5} offset={1}>
          {/* <Box /> */}
          <Html center portal={domContent}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  // backgroundColor: "black",
                  width: "40vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 100,
                  borderRadius: 100,
                }}
              >
                <h2>
                  <a href="/expertise" style={{ color: "white" }}>
                    Live Demos
                  </a>
                </h2>
              </div>
              <div
                style={{
                  // backgroundColor: "white",
                  marginTop: -100,
                  minWidth: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 50,
                  borderRadius: 100,
                }}
              >
                <Container className="text-center">
                  <Row>
                  <Col sm="4">
                      <a href="https://dev.celiumtickets.com">
                        <img
                          alt="..."
                          className="img-raised"
                          src={
                            require("assets/img/tickettreesprofile.png").default
                          }
                        />
                      </a>
                      <Button
                        className="btn-simple btn-round"
                        color="primary"
                        href="https://dev.celiumtickets.com"
                        // tag={Link}
                      >
                        Celium Tickets (Substrate)
                      </Button>
                    </Col>
                    <Col sm="4">
                      <a href="https://mintoriginio.herokuapp.com">
                        <img
                          alt="..."
                          className="img-raised"
                          src={
                            require("assets/img/mintoriginprofile.png").default
                          }
                        />
                      </a>
                      <Button
                        className="btn-simple btn-round"
                        color="primary"
                        to="https://mintoriginio.herokuapp.com"
                        // tag={Link}
                      >
                        Mint Origin (Ethereum)
                      </Button>
                    </Col>
                    <Col sm="4">
                      <a href="https://worldofkoto.com">
                        <img
                          alt="..."
                          className="img-raised"
                          src={
                            require("assets/img/worldofkoto.png").default
                          }
                        />
                      </a>
                      <Button
                        className="btn-simple btn-round"
                        color="primary"
                        to="https://worldofkoto.com"
                        // tag={Link}
                      >
                        World of Koto (Avalanche) (CTO)
                      </Button>
                    </Col>
                  </Row>
                </Container>

                {/* <Examples /> */}
              </div>
            </div>
          </Html>
        </Block>
        <Block factor={1.5} offset={4}>
          <Box />
        </Block>
        <Block factor={1.5} offset={3}>
          <Box />
          <Html center portal={domContent}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "black",
                  width: "35vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 100,
                  borderRadius: 100,
                }}
              >
                <h2>
                  <a href="/expertise" style={{ color: "white" }}>
                    Expertise
                  </a>
                </h2>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  marginTop: -100,
                  minWidth: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 50,
                  borderRadius: 100,
                }}
              >
                <Technology />
              </div>
            </div>
          </Html>
        </Block>

        <Block factor={-2} offset={5}>
          <Box />
          <Html center portal={domContent}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "black",
                  width: "35vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 100,
                  borderRadius: 100,
                }}
              >
                <h2>
                  <a href="/certificates" style={{ color: "white" }}>
                    Certificates
                  </a>
                </h2>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  marginTop: -100,
                  minWidth: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 50,
                  borderRadius: 100,
                }}
              >
                <Container className="text-center">
                  <Row>
                    <Col sm="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={
                          require("assets/img/hyperledgercertificate.jpeg")
                            .default
                        }
                        style={{ maxHeight: "30vh" }}
                      />
                    </Col>
                    <Col sm="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={
                          require("assets/img/hyperledgerfabric2.jpeg").default
                        }
                        style={{ maxHeight: "30vh" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 50 }}>
                    <Col sm="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={
                          require("assets/img/cordacertificate.jpeg").default
                        }
                        style={{ maxHeight: "30vh" }}
                      />
                    </Col>
                    <Col sm="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={
                          require("assets/img/ariescertificate.jpeg").default
                        }
                        style={{ maxHeight: "30vh" }}
                      />
                    </Col>
                  </Row>
                </Container>

                {/* <Examples /> */}
              </div>
            </div>
          </Html>
        </Block>
      </Canvas>

      <div
        className="scrollArea"
        ref={scrollArea}
        onScroll={onScroll}
        {...events}
      >
        <div style={{ position: "sticky", top: 0 }} ref={domContent} />
        <div style={{ height: `${state.pages * 100}vh` }} />
      </div>
    </>
  );
}

export default App;
