/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// reactstrap components
import {
  Button,
  FormGroup,
  Container,
  Row,
  Col,
  UncontrolledCarousel,
} from "reactstrap";

const carouselItems = [
  {
    src: require("assets/img/blockchainPlatform.png").default,
    altText: "Slide 1",
    caption: "",
  },
];

export default function JavaScript() {
  const [demoModal, setDemoModal] = React.useState(false);
  const [miniModal, setMiniModal] = React.useState(false);
  const [formModal, setFormModal] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  return (
    <div className="section section-javascript" id="javascriptComponents">
      <div className="section">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col className="mb-5 mb-lg-0" lg="5">
              <h1 className="text-white font-weight-light">
                Blockchain as a Service
              </h1>
              <p className="text-white mt-4">
                Cloud run solutions to host your blockchain infrastructure,
                smart contracts and other unique tools designed specifically for
                your business logic.
              </p>
              {/* <Button
                className="mt-4"
                color="info"
                href="https://demos.creative-tim.com/blk-design-system-react/#/documentation/alert"
              >
                Learn More
              </Button> */}
            </Col>
            <Col lg="6">
              <UncontrolledCarousel
                items={carouselItems}
                indicators={false}
                autoPlay={false}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// // react plugin used to create charts
// //import { Line } from "react-chartjs-2";
// // reactstrap components
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   CardFooter,
//   CardTitle,
//   ListGroupItem,
//   ListGroup,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";

// // core components
// import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
// import Footer from "components/Footer/Footer.js";

// import bigChartData from "variables/charts.js";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   },
// }));

// export default function CenteredGrid(props) {
//   const classes = useStyles();
//   const {width} = props
//   const w1 = width>800? 6:12
//   const w2 = width>800? 4:12
//   return (
//     <div className={classes.root}>
//             <Row>
//               <Col lg="6" md="6">
//                 <h1 className="text-white">
//                   Blockchain as a Service
//                 </h1>
//                 <p className="text-white mb-3">
//                 </p>
//                 <div className="btn-wrapper mb-3">
//                   <p className="category text-success d-inline">
//                     Learn more
//                   </p>
//                   <Button
//                     className="btn-link"
//                     color="success"
//                     href="#pablo"
//                     onClick={(e) => e.preventDefault()}
//                     size="sm"
//                   >
//                     <i className="tim-icons icon-minimal-right" />
//                   </Button>
//                 </div>
//                 <div className="btn-wrapper">
//                   <div className="button-container">
//                     <Button
//                       className="btn-icon btn-simple btn-round btn-neutral"
//                       color="default"
//                       href="#pablo"
//                       onClick={(e) => e.preventDefault()}
//                     >
//                       <i className="fab fa-twitter" />
//                     </Button>
//                     <Button
//                       className="btn-icon btn-simple btn-round btn-neutral"
//                       color="default"
//                       href="#pablo"
//                       onClick={(e) => e.preventDefault()}
//                     >
//                       <i className="fab fa-dribbble" />
//                     </Button>
//                     <Button
//                       className="btn-icon btn-simple btn-round btn-neutral"
//                       color="default"
//                       href="#pablo"
//                       onClick={(e) => e.preventDefault()}
//                     >
//                       <i className="fab fa-facebook" />
//                     </Button>
//                   </div>
//                 </div>
//               </Col>
//               <Col lg="4" md="5">
//                 <img
//                   alt="..."
//                   className="img-fluid"
//                   src={require("assets/img/blockchainPlatform.png").default}
//                 />
//               </Col>
//             </Row>

//     </div>
//   );
// }

// export default function LandingPage() {
//   React.useEffect(() => {
//     document.body.classList.toggle("landing-page");
//     // Specify how to clean up after this effect:
//     return function cleanup() {
//       document.body.classList.toggle("landing-page");
//     };
//   },[]);
//   return (
//     <>
//       <ExamplesNavbar />
//       <div className="wrapper">
//         <div className="page-header">
//           {/* <img
//             alt="..."
//             className="path"
//             src={require("assets/img/blob.png").default}
//           /> */}
//           {/* <img
//             alt="..."
//             className="path2"
//             src={require("assets/img/path2.png").default}
//           /> */}

//         </div>

//       </div>
//     </>
//   );
// }
