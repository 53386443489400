/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footer/Footer.js";
import AnimatedNumber from "animated-number-react";

import Web3 from "web3";
const carouselItems = [
  {
    src: require("assets/img/substrate1.jpg").default,
    // altText: "Slide 1",
    // caption: "Big City Life, United States",
  },
  {
    src: require("assets/img/substrate2.jpg").default,
    // altText: "Slide 2",
    // caption: "Somewhere Beyond, United States",
  },
  {
    src: require("assets/img/fabric3.jpg").default,
    // altText: "Slide 3",
    // caption: "Stocks, United States",
  },
];

let ps = null;

export default function ProfilePage() {
  const [tabs, setTabs] = React.useState(1);
  const [block, setBlock] = React.useState("loading...");
  const [nonce, setNonce] = React.useState("loading...");
  const [difficulty, setDifficulty] = React.useState("loading...");

  const ethereumListeners = async () => {
    const web3 = new Web3(
      new Web3.providers.WebsocketProvider(
        "wss://mainnet.infura.io/ws/v3/d2d9baedf3004d239ce77eac2f2f08d3"
      )
    );
    console.log("at subscription");
    web3.eth
      .subscribe("newBlockHeaders", (error, blockHeader) => {
        if (error) return console.error(error);

        console.log("Successfully subscribed!", blockHeader);
      })
      .on("data", (blockHeader) => {
        console.log("data: ", blockHeader);
        setBlock(blockHeader.number);
        setNonce(blockHeader.nonce);
        setDifficulty(blockHeader.difficulty);
      });

    // unsubscribes the subscription
    // subscription.unsubscribe((error, success) => {
    // if (error) return console.error(error);

    // console.log('Successfully unsubscribed!');
    // });
  };

  React.useEffect(() => {
    // ethereumListeners();
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("profile-page");
    };
  }, []);
  
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper" style={{ overflow: "scroll", height: "100%", backgroundColor:'#205374'}}>
        <div className="page-header">
          <img
            alt="..."
            className="dots"
            src={require("assets/img/dots.png").default}
          />
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png").default}
          />
          <Container className="align-items-center">
            <Row>
              <Col lg="9" md="9" >
                <h1 className="profile-title text-left" style={{position:'relative',color:'#30ce88',top:0}}>Substrate</h1>
                <p className="profile-description" style={{marginTop:20,color:'white',fontSize:20,fontFamily:'Inter',minWidth:'90%'}}>
                  Substrate is a highly modular blockchain framework that allows
                  developers to completely customize and optimize their
                  blockchain-based solution using open-source or custom-built
                  components. It is a fork of the Polkadot blockchain
                  repository, a firmly top 10 crypto by market size. This gives
                  the techno logy a reputability for security and high
                  transaction throughput. In fact, deploying your own
                  substrate-based blockchain could see your scalability concerns
                  disappear as its Proof of Authority consensus mechanism can
                  scale considerably beyond Proof of Stake or Proof of Work
                  consensus mechanism.
                </p>
              </Col>
              <Col className="ml-auto mr-auto" lg="3" md="6">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid rounded-circle"
                      src={require("assets/img/substrate.png").default}
                    />
                    <h4 className="title">Substrate</h4>
                  </CardHeader>
                  <CardBody>
                    <Nav
                      className="nav-tabs-primary justify-content-center"
                      tabs
                    >
                      <NavItem>
                        {/* <NavLink
                          className={classnames({
                            active: tabs === 1,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(1);
                          }}
                          href="#pablo"
                        >
                          Network
                        </NavLink> */}
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 2,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(2);
                          }}
                          href="#pablo"
                        >
                          Send
                        </NavLink>
                      </NavItem> */}
                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 3,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(3);
                          }}
                          href="#pablo"
                        >
                          News
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                    <TabContent
                      className="tab-subcategories"
                      activeTab={"tab" + tabs}
                      style={{ overflow: "hidden" }}
                    >
                      <TabPane tabId="tab1" style={{ overflow: "hidden" }}>
                        {/* <Table
                          className="tablesorter"
                          responsive
                          style={{ overflow: "hidden" }}
                        >
                          <thead className="text-primary">
                            <tr>
                              <th className="header">COIN</th>
                              <th className="header">AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Block #</td>
                              <td>{block}</td>
                            </tr>
                            <tr>
                              <td>Nonce</td>
                              <td>{nonce}</td>
                            </tr>
                            <tr>
                              <td>Difficulty</td>
                              <td>{difficulty}</td>
                            </tr>
                          </tbody>
                        </Table> */}
                      </TabPane>
                      <TabPane tabId="tab2">
                        <Row>
                          <Label sm="3">Pay to</Label>
                          <Col sm="9">
                            <FormGroup>
                              <Input
                                placeholder="e.g. 1Nasd92348hU984353hfid"
                                type="text"
                              />
                              <FormText color="default" tag="span">
                                Please enter a valid address.
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="3">Amount</Label>
                          <Col sm="9">
                            <FormGroup>
                              <Input placeholder="1.587" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-simple btn-icon btn-round float-right"
                          color="primary"
                          type="submit"
                        >
                          <i className="tim-icons icon-send" />
                        </Button>
                      </TabPane>
                      <TabPane tabId="tab3">
                        <Table className="tablesorter" responsive>
                          <thead className="text-primary">
                            <tr>
                              <th className="header">Latest Crypto News</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>The Daily: Nexo to Pay on Stable...</td>
                            </tr>
                            <tr>
                              <td>Venezuela Begins Public of Nation...</td>
                            </tr>
                            <tr>
                              <td>PR: BitCanna – Dutch Blockchain...</td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <Container>
            <Row className="justify-content-between">
              <Col md="6">
                <Row className="justify-content-between align-items-center">
                  <UncontrolledCarousel items={carouselItems} />
                </Row>
              </Col>
              <Col md="5">
                <h1 className="profile-title text-left" style={{color:'#30ce88',top:0,position:'relative'}}>
                  Substrate Modularity
                </h1>
                {/* <h5 className="text-on-back">02</h5> */}
                <p className="profile-description text-left" style={{marginTop:40, color:'white',fontSize:20,fontFamily:'Inter'}}>
                  The substrate framework provides specific instructions on how
                  to add an existing pallet or create your own pallet. These
                  pallets are the building blocks that you can add to your
                  blockchain to completely customize the network. There is a
                  specific list of official pallets provided by substrate that
                  can be of use, included in substrates repository. Notable ones
                  include the ink pallet, which allows you to run smart
                  contracts, the batch pallet, which allows batching
                  transactions together.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section">
          <Container className="align-items-center">
            <Row>
              <Col lg="6" md="6">
                {/* <h1 className="profile-title text-left">03</h1> */}
                <h5 className="text-on-back">Pros</h5>
                <p className="profile-description" style={{color:'white',fontSize:20,fontFamily:'Inter'}}>
                  Substrate truly is the most modular blockchain framework
                  currently available, one could refer to it as the build-a-bear
                  of blockchains. This means that almost any blockchain use case
                  could in theory be done using substrate. Furthermore, it has
                  specific features to enable runtime upgrades which allows
                  nodes to upgrade during runtime without having to restart the
                  node. The combination of modularity and runtime upgrades
                  further pushes the story that it is by far the most modular
                  and flexible blockchain framework.
                </p>
              </Col>
              <Col className="ml-auto mr-auto" lg="6" md="6">
                <h1 className="profile-title text-left"></h1>
                <h5 className="text-on-back">Cons</h5>
                <p className="profile-description" style={{color:'white',fontSize:20,fontFamily:'Inter'}}>
                  Substrate is the newest blockchain framework mentioned on this
                  site, it was one of the newest blockchain frameworks available
                  to developers. Because of this, the project at times lacks
                  some documentation on how to achieve certain tasks. The
                  documentation is improving every day, and they have great
                  demos and open source code that shows how the blockchain can
                  be modified, and how to integrate on front-end platforms as
                  well.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <Footer /> */}
      </div>
    </>
  );
}
