/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footer/Footer.js";
import AnimatedNumber from "animated-number-react";

import Web3 from "web3";
const carouselItems = [
  {
    src: require("assets/img/eth1.jpg").default,
    // altText: "Slide 1",
    // caption: "Big City Life, United States",
  },
  {
    src: require("assets/img/eth2.jpg").default,
    // altText: "Slide 2",
    // caption: "Somewhere Beyond, United States",
  },
  {
    src: require("assets/img/eth3.jpg").default,
    // altText: "Slide 3",
    // caption: "Stocks, United States",
  },
];

let ps = null;

export default function ProfilePage() {
  const [tabs, setTabs] = React.useState(1);
  const [block, setBlock] = React.useState("loading...");
  const [nonce, setNonce] = React.useState("loading...");
  const [difficulty, setDifficulty] = React.useState("loading...");

  const ethereumListeners = async () => {
    const web3 = new Web3(
      new Web3.providers.WebsocketProvider(
        "wss://mainnet.infura.io/ws/v3/d2d9baedf3004d239ce77eac2f2f08d3"
      )
    );
    console.log("at subscription");
    web3.eth
      .subscribe("newBlockHeaders", (error, blockHeader) => {
        if (error) return console.error(error);

        console.log("Successfully subscribed!", blockHeader);
      })
      .on("data", (blockHeader) => {
        console.log("data: ", blockHeader);
        setBlock(blockHeader.number);
        setNonce(blockHeader.nonce);
        setDifficulty(blockHeader.difficulty);
      });

    // unsubscribes the subscription
    // subscription.unsubscribe((error, success) => {
    // if (error) return console.error(error);

    // console.log('Successfully unsubscribed!');
    // });
  };
                
                
    // #205374 
  // #27a09e 
  // #30ce88 
  // #7de393 
  // #d3f5ce

  React.useEffect(() => {
    ethereumListeners();
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("profile-page");
    };
      // #205374 
  // #27a09e 
  // #30ce88 
  // #7de393 
  // #d3f5ce
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper" style={{ overflow: "scroll", height: "100%", backgroundColor:'#205374'}}>
        <div className="page-header">
          <img
            alt="..."
            className="dots"
            src={require("assets/img/dots.png").default}
          />
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png").default}
          />
          <Container className="align-items-center">
            <Row>
              <Col lg="8" md="9">
                <h1 style={{color:'#30ce88'}}>Ethereum</h1>
                {/* <h5 className="text-on-back">01</h5> */}
                <p className="profile-description" style={{marginTop:20,color:'white',fontSize:20,fontFamily:'Inter',minWidth:'90%'}}>
                  Ethereum's current consensus mechanism is proof of work, in
                  which the next block is proposed by the miner who can guess a
                  value, that when it is hashed will produce a string meeting a
                  certain condition. The condition will have a certain
                  'difficulty' to it to guarantee some average time between
                  blocks being added to the blockchain. This method 'randomly'
                  allots the next block to a miner, assuring that no one entity
                  or organization could prevent, exclude or remove any
                  transactions. The transactions included in these blocks have
                  to be validated by a majority of the nodes running on the
                  network, assuring that only valid transactions are included in
                  the ledger.
                </p>
              </Col>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid rounded-circle"
                      src={require("assets/img/etherum.png").default}
                    />
                    <h4 className="title" style={{color:'#d3f5ce'}}>Ethereum</h4>
                  </CardHeader>
                  <CardBody>
                    <Nav
                      className="nav-tabs-primary justify-content-center"
                      tabs
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 1,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(1);
                          }}
                          href="#pablo"
                        >
                          Network
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 2,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(2);
                          }}
                          href="#pablo"
                        >
                          Send
                        </NavLink>
                      </NavItem> */}
                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 3,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(3);
                          }}
                          href="#pablo"
                        >
                          News
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                    <TabContent
                      className="tab-subcategories"
                      activeTab={"tab" + tabs}
                      style={{ overflow: "hidden" }}
                    >
                      <TabPane tabId="tab1" style={{ overflow: "hidden" }}>
                        <Table
                          className="tablesorter"
                          responsive
                          style={{ overflow: "hidden" }}
                        >
                          <thead className="text-primary">
                            <tr>
                              <th className="header">COIN</th>
                              <th className="header">AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Block #</td>
                              <td>{block}</td>
                            </tr>
                            <tr>
                              <td>Nonce</td>
                              <td>{nonce}</td>
                            </tr>
                            <tr>
                              <td>Difficulty</td>
                              <td>{difficulty}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPane>
                      <TabPane tabId="tab2">
                        <Row>
                          <Label sm="3">Pay to</Label>
                          <Col sm="9">
                            <FormGroup>
                              <Input
                                placeholder="e.g. 1Nasd92348hU984353hfid"
                                type="text"
                              />
                              <FormText color="default" tag="span">
                                Please enter a valid address.
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="3">Amount</Label>
                          <Col sm="9">
                            <FormGroup>
                              <Input placeholder="1.587" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-simple btn-icon btn-round float-right"
                          color="primary"
                          type="submit"
                        >
                          <i className="tim-icons icon-send" />
                        </Button>
                      </TabPane>
                      <TabPane tabId="tab3">
                        <Table className="tablesorter" responsive>
                          <thead className="text-primary">
                            <tr>
                              <th className="header">Latest Crypto News</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>The Daily: Nexo to Pay on Stable...</td>
                            </tr>
                            <tr>
                              <td>Venezuela Begins Public of Nation...</td>
                            </tr>
                            <tr>
                              <td>PR: BitCanna – Dutch Blockchain...</td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <Container>
            <Row className="justify-content-between">
              <Col md="6">
                <Row className="justify-content-between align-items-center">
                  <UncontrolledCarousel items={carouselItems} />
                </Row>
              </Col>
              <Col md="5">
                <h1 style={{color:'#30ce88'}}> EVM & Solidity</h1>
                {/* <h5 className="text-on-back">02</h5> */}
                <p className="profile-description text-left" style={{color:'white',fontSize:20,fontFamily:'Inter',marginTop:20}}>
                  The Ethereum Virtual Machine (EVM) is a virtual stack included
                  in each node running on the ethereum network. This part of the
                  node is responsible for executing the input data of the
                  contract, also known as bytecode. This bytecode is generated
                  through higher level languages such as vyper and solidity,
                  making it easy for developers to run decentralized
                  applications on top of the ethereum blockchain.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section">
          <Container className="align-items-center">
            <Row>
              <Col lg="6" md="6">
                {/* <h1 className="profile-title text-left">03</h1> */}
                <h5 className="text-on-back">Pros</h5>
                <p className="profile-description" style={{color:'white',fontSize:20,fontFamily:'Inter'}}>
                  Ethereum currently provides the most trusted and stable smart
                  contract platform. The sheer amount of validating power
                  together with the array of test open-source smart contracts
                  assures a degree of security that is currently unrivaled. In
                  addition to this, ethereum has one of the strongest developer
                  communities standing by it. The network is poised for imminent
                  scaling upgrades known as eth2.0 which will combine
                  distributed proof of stake, network sharding, and other
                  scaling solutions. This will put it into an excellent position
                  to make it the world’s largest decentralized computer!
                </p>
              </Col>
              <Col className="ml-auto mr-auto" lg="6" md="6">
                <h1 className="profile-title text-left"></h1>
                <h5 className="text-on-back">Cons</h5>
                <p className="profile-description" style={{color:'white',fontSize:20,fontFamily:'Inter'}}>
                  Currently, ethereum is one of the most transacted networks,
                  meaning that it has more daily transactions than any other
                  cryptocurrency. This is due to the broad spectrum of
                  functionality that smart contracts enable such as NFT’s,
                  Stable coins, DEFI, Decentralized Exchanges). However, because
                  of this introducing new transactions are highly competitive
                  and expensive. Especially when the ethereum network
                  experiences peaks in activity, transaction fees have spiked to
                  unusable numbers. Current upgrades such as EIP-1559 have
                  stabilized transaction fees, while the upcoming eth2.0
                  upgrades should see them lower even further so that the fees
                  are no longer a significant concern.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <Footer /> */}
      </div>
    </>
  );
}

  // #205374 
  // #27a09e 
  // #30ce88 
  // #7de393 
  // #d3f5ce