/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footer/Footer.js";
import AnimatedNumber from "animated-number-react";

import Web3 from "web3";
const carouselItems = [
  {
    src: require("assets/img/fabric1.jpg").default,
    // altText: "Slide 1",
    // caption: "Big City Life, United States",
  },
  {
    src: require("assets/img/fabric2.jpg").default,
    // altText: "Slide 2",
    // caption: "Somewhere Beyond, United States",
  },
  {
    src: require("assets/img/fabric3.jpg").default,
    // altText: "Slide 3",
    // caption: "Stocks, United States",
  },
];

let ps = null;

export default function ProfilePage() {
  const [tabs, setTabs] = React.useState(1);
  const [block, setBlock] = React.useState("loading...");
  const [nonce, setNonce] = React.useState("loading...");
  const [difficulty, setDifficulty] = React.useState("loading...");

  const ethereumListeners = async () => {
    const web3 = new Web3(
      new Web3.providers.WebsocketProvider(
        "wss://mainnet.infura.io/ws/v3/d2d9baedf3004d239ce77eac2f2f08d3"
      )
    );
    console.log("at subscription");
    web3.eth
      .subscribe("newBlockHeaders", (error, blockHeader) => {
        if (error) return console.error(error);

        console.log("Successfully subscribed!", blockHeader);
      })
      .on("data", (blockHeader) => {
        console.log("data: ", blockHeader);
        setBlock(blockHeader.number);
        setNonce(blockHeader.nonce);
        setDifficulty(blockHeader.difficulty);
      });

    // unsubscribes the subscription
    // subscription.unsubscribe((error, success) => {
    // if (error) return console.error(error);

    // console.log('Successfully unsubscribed!');
    // });
  };

  React.useEffect(() => {
    // ethereumListeners();
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("profile-page");
    };
  }, []);

    // #205374 
  // #27a09e 
  // #30ce88 
  // #7de393 
  // #d3f5ce
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper" style={{ overflow: "scroll", height: "100%", backgroundColor:'#205374'}}>
        <div className="page-header">
          <img
            alt="..."
            className="dots"
            src={require("assets/img/dots.png").default}
          />
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png").default}
          />
          <Container className="align-items-center">
            <Row>
              <Col lg="9" md="9">
                <h1 style={{color:'#30ce88'}}>Hyperledger Fabric</h1>
                {/* <h5 className="text-on-back">01</h5> */}
                <p className="profile-description" style={{marginTop:20,color:'white',fontSize:20,fontFamily:'Inter',minWidth:'90%'}}>
                  Hyperledger Farbic is a project under the Hyperledger family.
                  It is a project designed specifically to create permissioned
                  blockchains. A permissioned blockchain adds a control layer to
                  blockchains that assures that only a selected amount of
                  identifiable participants can run a node and perform actions
                  on a blockchain. It is a framework that is most closely
                  designed to support blockchain solutions for enterprise use
                  cases. It is due to its focus on confidentiality, resiliency,
                  flexibility and scalability that it is able to handle a strong
                  throughput of transactions combined with a high degree of
                  security. Hyperledger Fabric is quite modular, and thus also
                  allows for the implementation of zero-knowledge proof,
                  identity mixers and private data to name just a few.
                </p>
              </Col>
              <Col className="ml-auto mr-auto" lg="3" md="3">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid rounded-circle"
                      src={require("assets/img/hyperledgerFabric.png").default}
                    />
                    <h4 className="title">Fabric</h4>
                  </CardHeader>
                  <CardBody>
                    <Nav
                      className="nav-tabs-primary justify-content-center"
                      tabs
                    >
                      <NavItem>
                        {/* <NavLink
                          className={classnames({
                            active: tabs === 1,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(1);
                          }}
                          href="#pablo"
                        >
                          Network
                        </NavLink> */}
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 2,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(2);
                          }}
                          href="#pablo"
                        >
                          Send
                        </NavLink>
                      </NavItem> */}
                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 3,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(3);
                          }}
                          href="#pablo"
                        >
                          News
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                    <TabContent
                      className="tab-subcategories"
                      activeTab={"tab" + tabs}
                      style={{ overflow: "hidden" }}
                    >
                      <TabPane tabId="tab1" style={{ overflow: "hidden" }}>
                        {/* <Table
                          className="tablesorter"
                          responsive
                          style={{ overflow: "hidden" }}
                        >
                          <thead className="text-primary">
                            <tr>
                              <th className="header">COIN</th>
                              <th className="header">AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Block #</td>
                              <td>{block}</td>
                            </tr>
                            <tr>
                              <td>Nonce</td>
                              <td>{nonce}</td>
                            </tr>
                            <tr>
                              <td>Difficulty</td>
                              <td>{difficulty}</td>
                            </tr>
                          </tbody>
                        </Table> */}
                      </TabPane>
                      <TabPane tabId="tab2">
                        <Row>
                          <Label sm="3">Pay to</Label>
                          <Col sm="9">
                            <FormGroup>
                              <Input
                                placeholder="e.g. 1Nasd92348hU984353hfid"
                                type="text"
                              />
                              <FormText color="default" tag="span">
                                Please enter a valid address.
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="3">Amount</Label>
                          <Col sm="9">
                            <FormGroup>
                              <Input placeholder="1.587" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-simple btn-icon btn-round float-right"
                          color="primary"
                          type="submit"
                        >
                          <i className="tim-icons icon-send" />
                        </Button>
                      </TabPane>
                      <TabPane tabId="tab3">
                        <Table className="tablesorter" responsive>
                          <thead className="text-primary">
                            <tr>
                              <th className="header">Latest Crypto News</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>The Daily: Nexo to Pay on Stable...</td>
                            </tr>
                            <tr>
                              <td>Venezuela Begins Public of Nation...</td>
                            </tr>
                            <tr>
                              <td>PR: BitCanna – Dutch Blockchain...</td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <Container>
            <Row className="justify-content-between">
              <Col md="6">
                <Row className="justify-content-between align-items-center">
                  <UncontrolledCarousel items={carouselItems} />
                </Row>
              </Col>
              <Col md="5">
                <h1 style={{color:'#30ce88'}}>Fabric Chaincode</h1>
                {/* <h5 className="text-on-back">02</h5> */}
                <p className="profile-description text-left" style={{marginTop:50, color:'white',fontSize:20,fontFamily:'Inter'}}>
                  The entire transactional logic of hyperledger fabric lies in
                  its chaincode, which is the smart contract language that runs
                  on top of the infrastructure. Hyperledger fabric currently
                  includes various programming languages to run fabric
                  contracts, such as Go, Java, Typescript, and Javascript. The
                  whole organizational and transactional logic will run on these
                  contracts, and can be used to run erc20, erc721 (NFT)
                  contracts, while also allowing for the transaction of private
                  data, and proof of the existence of private data which can be
                  an immensely helpful tool on blockchains.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section">
          <Container className="align-items-center">
            <Row>
              <Col lg="6" md="6">
                {/* <h1 className="profile-title text-left">03</h1> */}
                <h5 className="text-on-back">Pros</h5>
                <p className="profile-description" style={{color:'white',fontSize:20,fontFamily:'Inter'}}>
                  One of the strongest benefits of hyperledger fabric is its
                  successful implementation of permissioned blockchain, it truly
                  serves as a model of what a permissioned blockchain can
                  achieve. Hyperledger Fabric allows enterprises to run scalable
                  permissioned blockchain solutions which allows it to take
                  advantage of features that permissionless blockchain
                  (Ethereum, Bitcoin, Cardano) simply do not have. This allows
                  for the custom creation of business logic using blockchain
                  technology, improving transparency, security and accessibility
                  over centralized systems.
                </p>
              </Col>
              <Col className="ml-auto mr-auto" lg="6" md="6">
                <h1 className="profile-title text-left"></h1>
                <h5 className="text-on-back">Cons</h5>
                <p className="profile-description" style={{color:'white',fontSize:20,fontFamily:'Inter',width:'100%'}}>
                  Hyperledger Fabric has a quite narrow range of use cases that
                  are specifically tailored to enterprise-level solutions.
                  Because of this, it often is the first place of choice for
                  people looking to deploy a decentralized application, as the
                  cost of setting up and maintaining a hyperledger fabric
                  network can be quite expensive. Furthermore, due to the new
                  nature of the technology, some developers have complained that
                  its SDK and API’s are underdeveloped and underdocumented.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <Footer /> */}
      </div>
    </>
  );
}
