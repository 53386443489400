import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
const useStyles = makeStyles((theme) => ({
  rooter: {
    flexGrow: 1,
    maxHeight: "150vh",
    overflowY: "scroll",
    overflowX: "hidden",
    paddingTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paragraph: {
    color: "black",
    fontFamily: "Inter",
    width: "100%",
    textAlign: "center",
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div className={classes.rooter}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card className="card-coin card-plain">
            <CardHeader>
              <img
                alt="..."
                className="img-center img-fluid"
                src={require("assets/img/ethereumToken.png").default}
              />
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="text-center" md="12">
                  <h4
                    className="text-uppercase"
                    style={{ color: "black", fontFamily: "Inter" }}
                  >
                    Ethereum
                  </h4>
                  <hr className="line-primary" />
                </Col>
              </Row>
              <Row>
                <span className={classes.paragraph}>
                  Ethereum is an innovative blockchain technology most known for
                  its facilitation of smart contracts running on top of its
                  infrastructure. It was first proposed by Vitalik Buternin in
                  2013 and went live on the 30th of July 2015. The concept of
                  smart contracts has been revolutionary, facilitating the
                  creation of decentralized finance, supply chain management,
                  the generation of rare collectibles to name just a few.
                </span>
              </Row>
            </CardBody>
            <CardFooter className="text-center">
              <Button
                className="btn-simple"
                color="primary"
                href="/ethereum"
                style={{zIndex:20}}
              >
                Learn more
              </Button>
            </CardFooter>
          </Card>{" "}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className="card-coin card-plain">
            <CardHeader>
              <img
                alt="..."
                className="img-center img-fluid"
                src={require("assets/img/hyperledgerFabric.png").default}
              />
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="text-center" md="12">
                  <h4
                    className="text-uppercase"
                    style={{ color: "black", fontFamily: "Inter" }}
                  >
                    Hyperledger Fabric
                  </h4>
                  <hr className="line-info" />
                </Col>
              </Row>
              <Row>
                <span className={classes.paragraph}>
                  Hyperledger Fabric is a leading open-source, modular
                  permissioned blockchain technology. This means that the ledger
                  is only accessible to known and trusted participants. Its
                  modularity and pluggable architecture make it a leading
                  solution for a variety of use cases. With its use of smart
                  contracts, private data, ZKP has given it tried and tested use
                  cases from logistics tracking to self-sovereign identity.
                </span>
              </Row>
            </CardBody>
            <CardFooter className="text-center">
              <Button className="btn-simple" color="info" href="/fabric">
                Learn more
              </Button>
            </CardFooter>
          </Card>{" "}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card className="card-coin card-plain">
            <CardHeader>
              <img
                alt="..."
                className="img-center img-fluid"
                src={require("assets/img/corda.png").default}
              />
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="text-center" md="12">
                  <h4
                    className="text-uppercase"
                    style={{ color: "black", fontFamily: "Inter" }}
                  >
                    Corda
                  </h4>
                  <hr className="line-danger" />
                </Col>
              </Row>
              <Row>
                <span className={classes.paragraph}>
                  Corda is a permissioned open-source distributed ledger
                  technology maintained by r3 designed specifically for business
                  use cases. It keeps transactions private to the involved
                  parties by default, assuring that different business entities
                  don't have to share unnecessary data. This emphasis on privacy
                  has made it an excellent technology for use cases in health
                  care, finance, and GovTech.
                </span>
              </Row>
            </CardBody>
            <CardFooter className="text-center">
              <Button className="btn-simple" color="danger">
                Learn more (coming soon!)
              </Button>
            </CardFooter>
          </Card>{" "}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card className="card-coin card-plain">
            <CardHeader>
              <img
                alt="..."
                className="img-center img-fluid"
                src={require("assets/img/quorum.png").default}
              />
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="text-center" md="12">
                  <h4
                    className="text-uppercase"
                    style={{ color: "black", fontFamily: "Inter" }}
                  >
                    Quorum
                  </h4>
                  <hr className="line-success" />
                </Col>
              </Row>
              <Row>
                <span className={classes.paragraph}>
                  Quorum is an open-source permissioned blockchain forked from
                  the GO implementation of ethereum. The project was first
                  started by JP Morgan but has since been passed along to
                  Cosensys who currently maintains the code repository. It is a
                  distributed ledger based on the principles of privacy,
                  modularity, and scalability making it another excellent choice
                  for various business use cases.
                </span>
              </Row>
            </CardBody>
            <CardFooter className="text-center">
              <Button className="btn-simple" color="success">
                Learn more (coming soon!)
              </Button>
            </CardFooter>
          </Card>{" "}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card className="card-coin card-plain">
            <CardHeader>
              <img
                alt="..."
                className="img-center img-fluid"
                src={require("assets/img/substrate.png").default}
              />
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="text-center" md="12">
                  <h4
                    className="text-uppercase"
                    style={{ color: "black", fontFamily: "Inter" }}
                  >
                    Parity Substrate
                  </h4>
                  <hr className="line-primary" />
                </Col>
              </Row>
              <Row>
                <span className={classes.paragraph}>
                  Substate is a highly unique and innovative blockchain
                  development platform that allows developers to create their
                  own blockchain. Its incredibly modular design allows for the
                  creation of permissioned/permissionless blockchain, the
                  addition of smart contracts, zero-knowledge proof, and much
                  more. If no existing permissioned or permissionless blockchain
                  meets your need, Substrate just might.
                </span>
              </Row>
            </CardBody>
            <CardFooter className="text-center">
              <Button className="btn-simple" color="primary" href="/substrate">
                Learn more
              </Button>
            </CardFooter>
          </Card>{" "}
        </Grid>
      </Grid>
    </div>
  );
}

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Paper from "@material-ui/core/Paper";
// import Grid from "@material-ui/core/Grid";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
// }));

// export default function CenteredGrid(props) {
//   const classes = useStyles();
//   const { width } = props;
//   const w1 = width > 800 ? 6 : 12;
//   const w2 = width > 800 ? 4 : 12;
//   return (
//     <div className={classes.root}>
//       <Row>
//         <Col md="4" style={{ marginTop: 100 }}>
//           <hr className="line-info" />
//           <div className="blur-hover">
//             <h1>
//               Have your distributed infrastructure built with the technology{" "}
//               <span className="text-info">that fits your needs</span>
//             </h1>
//           </div>
//         </Col>
//       </Row>
//       <Grid container spacing={3}>
//         <Grid item xs={w1}>
//           <div className="blur-hover">

//           </div>
//         </Grid>
//         <Grid item xs={w1}>

//         </Grid>
//         <Grid item xs={w2}>

//         </Grid>
//         <Grid item xs={w2}>

//         </Grid>
//         <Grid item xs={w2}>

//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// import React from 'react'

// export default function(){
//     return (
//         <section className="section section-lg section-coins">
//         <img
//           alt="..."
//           className="path"
//           src={require("assets/img/path3.png").default}
//         />
//         <Container>

//             <Col md="4">

//             </Col>
//             <Col md="4" style={{marginTop:100}}>

//             </Col>
//             <Col md="4" style={{marginTop:100}}>

//             </Col>
//             <Col md="4" style={{marginTop:100}}>

//             </Col>
//             <Col md="4" style={{marginTop:100}}>

//             </Col>
//           </Row>
//         </Container>
//       </section>
//     )
// }
